import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { auth } from 'firebase/app';
import {
  useMediaQuery,
  Box,
  Button,
  Form,
  FormInput,
  FormInputHeader,
  FormInputLabel,
  Link,
  Logo,
  Text,
  TextInput,
} from '@dishr/design';
import authErrors from './authErrors';
import useIsUserSignedIn from '../../utils/useIsUserSignedIn';
import Footer from '../Footer';
import { Page, PageBody, PageFooter } from '../Page';

export default function SignUp() {
  const match = useMediaQuery(['1000px']);
  const { replace } = useHistory();
  const isUserSignedIn = useIsUserSignedIn();
  const [error, setError] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [passwordConfirm, setPasswordConfirm] = React.useState('');

  const handleSignUp = async(event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      if (password !== passwordConfirm) {
        throw { code: 'auth/passwords-do-not-match' };
      }

      await auth().createUserWithEmailAndPassword(email, password);
      replace('/d');
    } catch (error) {
      setError(authErrors[error.code] ||
        'There was an issue signing up, please try again. ' +
        'If it keeps happening please get in contact.');
    }
  };

  React.useEffect(() => {
    if (isUserSignedIn) {
      replace('/d');
    }
  }, [isUserSignedIn, replace]);

  return (
    <Page backgroundColor="blue">
      <Box flex={ match('1000px') ? 'horizontal' : 'vertical' } grow>
        <Box
            basis="none"
            flex="vertical"
            grow>
          <PageBody padding="x20">
            <Logo size="4rem" textColor="light" />
          </PageBody>

          <PageFooter paddingHorizontal="x20" paddingVertical="x10">
            <Footer textColor="light" />
          </PageFooter>
        </Box>

        <Box
            backgroundColor="light"
            basis="none"
            borderColor="dark-gray"
            borderSides="L"
            borderSize="x1"
            grow
            padding="x20">
          <Form maxWidth="400px" onSubmit={ handleSignUp }>
            <Text margin="x8" size="x4">Let's get you an account.</Text>

            <FormInput margin="x6">
              <FormInputHeader>
                <FormInputLabel>Email</FormInputLabel>
              </FormInputHeader>

              <TextInput
                  onChange={ (e) => setEmail((e.target as HTMLInputElement).value) }
                  value={ email } />
            </FormInput>

            <FormInput margin="x6">
              <FormInputHeader>
                <FormInputLabel>Password</FormInputLabel>
              </FormInputHeader>

              <TextInput
                  onChange={ (e) => setPassword((e.target as HTMLInputElement).value) }
                  type="password"
                  value={ password } />
            </FormInput>

            <FormInput margin="x6">
              <FormInputHeader>
                <FormInputLabel>Confirm password</FormInputLabel>
              </FormInputHeader>

              <TextInput
                  onChange={ (e) => setPasswordConfirm((e.target as HTMLInputElement).value) }
                  type="password"
                  value={ passwordConfirm } />
            </FormInput>

            { error && (
              <Text margin="x4" textColor="red">{ error }</Text>
            ) }

            <Button
                color="blue"
                disabled={ !email || !password || !passwordConfirm }
                fullWidth
                level="primary"
                type="submit">
              Let's go
            </Button>

            <Text align="middle" margin="x8" textColor="dark-gray">
              Already have an account? <Link textColor="blue" to="/login">Sign in</Link>.
            </Text>
          </Form>
        </Box>
      </Box>
    </Page>
  );
}
