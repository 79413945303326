import * as React from 'react';
import { Box, BoxProps } from '@dishr/design';

interface PageBodyProps extends BoxProps {

}

export default function PageBody(props: React.PropsWithChildren<PageBodyProps>) {
  return (
    <Box { ...props } grow />
  );
}
