import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { auth } from 'firebase/app';
import {
  Button,
  Card,
  Form,
  FormInput,
  FormInputHeader,
  FormInputLabel,
  Link,
  Logo,
  Text,
  TextInput,
} from '@dishr/design';
import authErrors from './authErrors';
import useIsUserSignedIn from '../../utils/useIsUserSignedIn';
import Footer from '../Footer';
import { Page, PageBody, PageFooter } from '../Page';

export default function Login() {
  const { replace } = useHistory();
  const isUserSignedIn = useIsUserSignedIn();
  const [error, setError] = React.useState('');
  const [email, setEmail] = React.useState('');

  const handleSignIn = async(event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      await auth().sendPasswordResetEmail(email);
    } catch (error) {
      setError(authErrors[error.code] ||
        'There was an issue sending your password reminder email, please try again. ' +
        'If it keeps happening please get in contact.');
    }
  };

  React.useEffect(() => {
    if (isUserSignedIn) {
      replace('/d');
    }
  }, [isUserSignedIn, replace]);

  return (
    <Page
        backgroundColor="light-gray"
        padding="x6">
      <PageBody
          alignChildrenHorizontal="middle"
          flex="vertical"
          paddingVertical="x20">
        <Logo margin="x16" size="5rem" textColor="blue" />

        <Card
            backgroundColor="light"
            borderRadius="x2"
            maxWidth="28rem"
            padding="x10">
          <Form onSubmit={ handleSignIn }>
            <Text margin="x8" size="x4">Ooops, forgot your password?</Text>

            <FormInput margin="x6">
              <FormInputHeader>
                <FormInputLabel>Email</FormInputLabel>
              </FormInputHeader>

              <TextInput
                  onChange={ (e) => setEmail((e.target as HTMLInputElement).value) }
                  value={ email } />
            </FormInput>

            { error && (
              <Text margin="x4" textColor="red">{ error }</Text>
            ) }

            <Button
                color="blue"
                disabled={ !email }
                fullWidth
                level="primary"
                type="submit">
              Reset
            </Button>
          </Form>
        </Card>

        <Text align="middle" margin="x8" textColor="dark-gray">
          Don't have an account? <Link textColor="blue" to="/login/but-first">Sign up</Link>.
        </Text>
      </PageBody>

      <PageFooter>
        <Footer />
      </PageFooter>
    </Page>
  );
}
