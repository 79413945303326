import { useEffect, useState } from 'react';
import { auth } from 'firebase/app';

export default function useIsUserSignedIn() {
  const [isSignedIn, setIsSignedIn] = useState(!!auth().currentUser);

  useEffect(() => {
    return auth().onAuthStateChanged((user) => {
      if (user) {
        setIsSignedIn(true);
      } else {
        setIsSignedIn(false);
      }
    });
  }, []);

  return isSignedIn;
}
