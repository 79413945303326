import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Landing from './Landing';

export default function LandingIndex() {
  return (
    <Switch>
      <Route component={ Landing } />
    </Switch>
  );
}
