const errors: Record<string, string> = {
  'auth/email-already-in-use': 'Looks like you already have an account, head on over to the sign in page.',
  'auth/invalid-email': 'Your email address looks invalid, have another go.',
  'auth/passwords-do-not-match': 'Your passwords don\'t match, have another go.',
  'auth/user-not-found': 'Either your email or password aren\'t is incorrect, have another go.',
  'auth/weak-password': 'Your password is too weak, it needs at least 6 characters, have another go.',
  'auth/wrong-password': 'Either your email or password aren\'t is incorrect, have another go.',
};

export default errors;
