import * as React from 'react';
import { Box, Link, Text } from '@dishr/design';

interface FooterProps {
  textColor?: 'light' | 'dark';
}

export default function Footer(props: FooterProps) {
  const {
    textColor = 'dark',
    ...rest
  } = props;

  return (
    <Text { ...rest }
        alignChildren="middle"
        flex="horizontal"
        gap="x6" size="x2"
        textColor={ textColor }>
      <Box><Text>© Dishr</Text></Box>
      <Box><Link textColor={ textColor === 'dark' ? 'blue' : 'light' }>Contact</Link></Box>
      <Box><Link textColor={ textColor === 'dark' ? 'blue' : 'light' }>Privacy & Terms of Service</Link></Box>
    </Text>
  );
}
