import * as React from 'react';
import { useHistory } from 'react-router-dom';
import AppBar from './AppBar';
import { Page, PageBody, PageFooter } from '../Page';
import Footer from '../Footer';
import useIsUserSignedIn from '../../utils/useIsUserSignedIn';

export default function App() {
  const { replace } = useHistory();
  const isUserSignedIn = useIsUserSignedIn();

  React.useEffect(() => {
    if (!isUserSignedIn) {
      replace('/login');
    }
  }, [isUserSignedIn, replace]);

  return (
    <Page>
      <PageBody flex="vertical">
        <AppBar />

      </PageBody>

      <PageFooter padding="x6">
        <Footer />
      </PageFooter>
    </Page>
  );
}
