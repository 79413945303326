import * as React from 'react';
import { Box, BoxProps } from '@dishr/design';

interface PageFooterProps extends BoxProps {

}

export default function PageFooter(props: React.PropsWithChildren<PageFooterProps>) {
  return (
    <Box { ...props } />
  );
}
