import * as React from 'react';
import { auth } from 'firebase/app';
import { Box, Link, Logo } from '@dishr/design';

export default function AppBar() {
  const handleSignOut = () => {
    auth().signOut();
  };

  return (
    <Box
        alignChildrenVertical="middle"
        flex="horizontal"
        paddingHorizontal="x6"
        paddingVertical="x2">
      <Box grow>
        <Logo size="2rem" textColor="blue" />
      </Box>

      <Box>
        <Link onClick={ handleSignOut }>Sign Out</Link>
      </Box>
    </Box>
  );
}
