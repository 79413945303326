import * as React from 'react';
import { Box, BoxProps } from '@dishr/design';

interface PageProps extends BoxProps {

}

export default function Page(props: React.PropsWithChildren<PageProps>) {
  return (
    <Box { ...props } flex="vertical" grow />
  );
}
