import * as React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import App from './App/App';
import Landing from './Landing';
import Login from './Login';

export default function Root() {
  return (
    <BrowserRouter>
      <Switch>
        <Route component={ Login } path="/login" />
        <Route component={ App } path="/d" />
        <Route component={ Landing } />
      </Switch>
    </BrowserRouter>
  );
}
