import * as React from 'react';
import { Box, Logo } from '@dishr/design';

export default function Landing() {
  return (
    <Box
        alignChildrenHorizontal="middle"
        alignChildrenVertical="middle"
        flex="vertical"
        grow
        padding="x20"
        textColor="blue">
      <Box padding="x20">
        <Logo size="8rem" />
      </Box>
    </Box>
  );
}
