import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import SignUp from './SignUp';

export default function LoginIndex() {
  return (
    <Switch>
      <Route component={ ForgotPassword } path="/login/reset" />
      <Route component={ SignUp } path="/login/but-first" />
      <Route component={ Login } />
    </Switch>
  );
}
