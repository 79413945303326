import * as React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import Root from './components/Root';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/performance';
import './index.css';

initializeApp({
  apiKey: 'AIzaSyA2_EaWVUqFhpYzot0r6GVTKbQXO3Z1Jmo',
  authDomain: 'dishr-f6d20.firebaseapp.com',
  databaseURL: 'https://dishr-f6d20.firebaseio.com',
  projectId: 'dishr-f6d20',
  storageBucket: 'dishr-f6d20.appspot.com',
  messagingSenderId: '477821883455',
  appId: '1:477821883455:web:9c62c1f2b8f42bd622548a',
  measurementId: 'G-3X4EW8EYN5',
});

const rootElement = document.getElementById('Root');

if (rootElement) {
  render(
    <BrowserRouter>
      <Root />
    </BrowserRouter>, rootElement);
}
